import { BallPlinko, DoubleCard, PlayingCardClover, Wheel } from '@/icons/index'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

export default function Dropdown({
  icon,
  className,
  active,
  centered,
  label,
  defaultLabel,
  prefetch = false,
  href = '#',
  page,
  imgHeight,
  imgWidth,
  header,
  bottom,
  registerClass,
  loginClass,
  exploreClass,
  home,
  ...rest
}) {
  const router = useRouter()

  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={`flex items-center justify-center space-x-2 text-white px-3 py-2 rounded-lg cursor-pointer  ${
              registerClass
                ? 'flex flex-row-reverse justify-center  gap-x-2 bg-[#008000] w-full sm:w-auto'
                : loginClass
                ? 'flex flex-row-reverse justify-center gap-x-2 bg-[#FFFFFF]/10  w-full sm:w-auto'
                : exploreClass
                ? 'flex justify-center mt-2 flex-row-reverse gap-x-2 bg-[#008000]'
                : ''
            }
            ${active ? `bg-[#fff]/10 ${className}` : 'hover:bg-[#fff]/10'} ${
              router.pathname.includes(page)
                ? `bg-[#fff]/10 ${className}`
                : 'hover:bg-[#fff]/10'
            } ${centered ? 'justify-center' : ''}`}
            {...rest}
          >
            {icon}
            <span
              className={`${
                registerClass || loginClass
                  ? 'text-lg'
                  : exploreClass
                  ? 'text-sm'
                  : 'text-sm xl:text-base'
              }  font-medium font-inter whitespace-nowrap`}
            >
              <FormattedMessage id={label} defaultMessage={defaultLabel} />
            </span>
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-36 origin-top-right rounded-md bg-black/80 shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <NextLink
                    href={'/casino/plinko'}
                    className={`${
                      active
                        ? `bg-[#fff]/10 ${className}`
                        : 'hover:bg-[#fff]/10'
                    } group flex w-full space-x-3 items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <BallPlinko className="w-6 h-6 shrink-0" />
                    <span
                      className={`${
                        registerClass || loginClass
                          ? 'text-lg'
                          : exploreClass
                          ? 'text-sm'
                          : 'text-base'
                      }  font-medium font-inter whitespace-nowrap`}
                    >
                      Plinko
                    </span>
                  </NextLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <NextLink
                    href={'/casino/video-poker'}
                    className={`${
                      active
                        ? `bg-[#fff]/10 ${className}`
                        : 'hover:bg-[#fff]/10'
                    } group flex w-full space-x-3 items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <PlayingCardClover className="w-6 h-5 shrink-0" />
                    <span
                      className={`${
                        registerClass || loginClass
                          ? 'text-lg'
                          : exploreClass
                          ? 'text-sm'
                          : 'text-base'
                      }  font-medium font-inter whitespace-nowrap`}
                    >
                      Video Poker
                    </span>
                  </NextLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <NextLink
                    href={'/casino/blackjack'}
                    className={`${
                      active
                        ? `bg-[#fff]/10 ${className}`
                        : 'hover:bg-[#fff]/10'
                    } group flex w-full space-x-3 items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <DoubleCard className="w-6 h-6 shrink-0" />
                    <span
                      className={`${
                        registerClass || loginClass
                          ? 'text-lg'
                          : exploreClass
                          ? 'text-sm'
                          : 'text-base'
                      }  font-medium font-inter whitespace-nowrap`}
                    >
                      Blackjack
                    </span>
                  </NextLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <NextLink
                    href={'/casino/roulette'}
                    className={`${
                      active
                        ? `bg-[#fff]/10 ${className}`
                        : 'hover:bg-[#fff]/10'
                    } group flex w-full space-x-3 items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <Wheel className="w-6 h-5 shrink-0" />
                    <span
                      className={`${
                        registerClass || loginClass
                          ? 'text-lg'
                          : exploreClass
                          ? 'text-sm'
                          : 'text-base'
                      }  font-medium font-inter whitespace-nowrap`}
                    >
                      Roulette
                    </span>
                  </NextLink>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
