import HeaderButton from '@/components/button/HeaderButton'
import CasinoDisclosure from '@/components/casinoDisclosure'
import CasinoDropdown from '@/components/casinoDropdown'
import SportsDisclosure from '@/components/sportsDisclosure'
import SportsDropdown from '@/components/sportsDropdown'
import { getSportByName } from '@/constants/countries-leagues'
import { firebaseLogout } from '@/firebase/utils'
import useSidebar from '@/hooks/useSidebar'
import {
  Casino,
  F1,
  GamesHeader,
  Golf,
  HomeHashtag,
  LilbitLogo,
  Ranking,
  WalletSecond,
} from '@/icons/index'
import authSelector from '@/redux/selectors/auth'
import betSlipsSelector from '@/redux/selectors/betSlips'
import walletSelector from '@/redux/selectors/wallet'
import { changeMenuDisplayType, clearBetsData } from '@/redux/slices/betSlips'
import { changePastBet } from '@/redux/slices/bets'
import CustomImage from '@/utils/customImage'
import { tabNavigation } from '@/utils/helper'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Menu, Popover, Transition } from '@headlessui/react'
import {
  AnnotationIcon,
  ChatAltIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  LogoutIcon,
  MenuAlt1Icon,
  XIcon,
} from '@heroicons/react/outline'
import { AdjustmentsIcon } from '@heroicons/react/solid'
import dynamic from 'next/dynamic'
import { default as Link, default as NextLink } from 'next/link'
import { useRouter } from 'next/router'
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import Chat from '../chat'
import { SecondaryIconButton } from '../sportsidebar'
import { ChangeMenuType } from './MenuType'
import LanguageSelector from './language'

let defaultNavigation = [
  {
    label: 'navbar.home',
    defaultLabel: 'Home',
    icon: <HomeHashtag className="w-5 h-5" />,
    href: '/home',
    page: 'home',
  },
  {
    label: 'navbar.casino',
    defaultLabel: 'Casino',
    icon: <Casino className="w-5 h-5" />,
    href: '/casino/plinko',
    page: 'casino',
  },
  {
    label: 'navbar.sports',
    defaultLabel: 'Sports',
    icon: <GamesHeader className="w-5 h-5" />,
    href: '/sports/popular-events',
    page: 'sports',
  },
  {
    label: 'navbar.predictionMarket',
    defaultLabel: 'Prediction Market',
    icon: <AdjustmentsIcon className="w-5 h-5" />,
    href: '/prediction-market',
    page: 'prediction-market',
  },
  {
    label: 'navbar.leaderboard',
    defaultLabel: 'Leaderboard',
    icon: <Ranking className="w-5 h-5" />,
    href: '/leaderboard',
    page: 'leaderboard',
  },
]

const Model = dynamic(() => import('../chat/chatModal'), {
  ssr: false,
})

function Header({
  isOpen,
  isSidebarOpen,
  width,
  showIcons = true,
  language,
  setLanguage,
}) {
  const dispatch = useDispatch()
  const [navigations, setNavigations] = useState(tabNavigation)
  const chatEnabled = useFeatureIsOn('chat')
  const router = useRouter()
  const containerRef = useRef(null)
  const [userIm, setUserIm] = useState(null)
  const uniqueNavigation = [...new Set([...navigations])]
  const { isAuthenticated: isUserLoggedIn, user } = useSelector(authSelector)
  const { betslipsOpenMobile, menuDisplayType } = useSelector(betSlipsSelector)
  const { internalWallet } = useSelector(walletSelector)
  const multiLanguageEnabled = useFeatureIsOn('language')
  const [navigation, setNavigation] = useState(defaultNavigation)
  const predictionMarketEnabled = useFeatureIsOn('predictionmarket')

  const sm = useMediaQuery({ query: '(max-width: 639px)' })
  const { sports, chat, setChat, ...rest } = useSidebar()
  const sportsData = useMemo(() => {
    return (
      (sports?.length && sports?.map((sport) => getSportByName(sport))) || []
    )
  }, [sports])
  const sortedSports = useMemo(() => {
    return sportsData?.sort((a, b) => a.index - b.index)
  }, [sportsData])

  const logoutHandler = async () => {
    dispatch(clearBetsData())
    dispatch(changePastBet(false))
    await firebaseLogout()
  }

  const signinHandler = () => {
    router.push('/login')
  }

  useEffect(() => {
    if (!predictionMarketEnabled)
      setNavigation((prev) =>
        prev.filter((nav) => nav.page !== 'prediction-market'),
      )
  }, [predictionMarketEnabled])

  useEffect(() => {
    if (user?.roleType === 'admin') {
      const ind = navigations.findIndex((nav) => nav.name === 'Enquiries')
      if (ind === -1) {
        setNavigations([
          ...new Set([
            ...navigations,
            {
              name: 'Enquiries',
              index: 6,
              icon: <AnnotationIcon className="w-5 h-5 text-green-700" />,
              href: '/settings?tab=enquiries',
            },
          ]),
        ])
      }
    } else {
      setNavigation(defaultNavigation)
      setUserIm(user?.photoPATH || '/img/blank-profil-picture.webp')
    }
  }, [user])

  const handleLeftArrowClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 100
    }
  }

  const handleRightArrowClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 100
    }
  }

  const intl = useIntl()

  return (
    <Popover
      className={`fixed ${width} ml-auto right-0 bg-[#000000]/5 backdrop-blur-md z-30 ${
        sm && betslipsOpenMobile && 'hidden'
      }`}
    >
      <div className="w-full flex items-center justify-between px-6 sm:px-8 py-4 flex-nowrap gap-2 ">
        <div className={`items-center gap-2 hidden md:flex`}>
          {!isSidebarOpen && (
            <Link href="/home" className="relative">
              <LilbitLogo className="mr-8 w-[6.5rem] h-8 cursor-pointer" />
            </Link>
          )}

          <>
            {navigation.map((item, index) => {
              if (item.page === 'casino') {
                return <CasinoDropdown key={index} {...item} header />
              } else if (item.page === 'sports') {
                return <SportsDropdown key={index} {...item} header />
              } else {
                return <HeaderButton key={index} {...item} header />
              }
            })}
          </>

          {user?.roleType === 'admin' && user && (
            <ChangeMenuType
              activeMenuType={menuDisplayType}
              onChange={(val) => dispatch(changeMenuDisplayType(val))}
            />
          )}
        </div>

        <div className={`flex gap-x-4 items-center md:hidden`}>
          <Popover.Button as={Fragment}>
            <div
              className={`text-white rounded-lg cursor-pointer w-10 h-10 flex items-center justify-center`}
            >
              <MenuAlt1Icon className="w-6 h-6 text-gray-300" />
            </div>
          </Popover.Button>
          <Link href="/home" className="relative block sm:hidden">
            <LilbitLogo className="mr-8 w-[3.5rem] h-8 cursor-pointer" />
          </Link>
        </div>

        <div className={`items-center gap-2 flex flex-nowrap`}>
          {user && (
            <div
              className="h-[40px] sm:h-[30px] w-[139px] sm:w-[30px] bg-[#fff]/10 sm:bg-inherit gap-x-1 flex sm:hidden items-center justify-between rounded-3xl px-2 sm:px-0 cursor-pointer"
              onClick={() => router.push('/settings?tab=wallet')}
            >
              <div className="flex-1 font-bold text-md mt-1 ml-1 overflow-hidden">
                {`$${internalWallet.balanceUsd || 0}`}
              </div>
              <div className="relative ">
                <WalletSecond className="w-5 h-5" />
              </div>
            </div>
          )}
          {chatEnabled && (
            <button
              onClick={setChat}
              type="button"
              className="focus:outline-none font-medium rounded-lg text-sm text-center inline-flex items-center"
            >
              <ChatAltIcon className="w-6 h-6 text-gray-300" />
            </button>
          )}
          {multiLanguageEnabled && (
            <LanguageSelector setLanguage={setLanguage} language={language} />
          )}
          {/* Profile dropdown */}
          {user ? (
            <Menu as="div" className="relative ml-3 hidden sm:block ">
              <div>
                <Menu.Button as={'button'}>
                  <div className="w-10 h-10 rounded-full cursor-pointer relative">
                    <CustomImage
                      src={userIm}
                      cls="object-contain rounded-full w-9 h-9"
                    />
                  </div>
                </Menu.Button>
              </div>
              {/* PopUp of Profile */}
              <Transition
                timeout={300}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute z-[100] bg-black backdrop right-0 mt-4 w-fit space-y-1 origin-top-right rounded-xl  pt-4 shadow-lg focus:outline-none overflow-y-scroll max-h-[80vh]">
                  <div className="overflow-y-auto max-h-[calc(80vh - 4rem)]">
                    {uniqueNavigation.map((tab, index) => {
                      return (
                        <Menu.Item key={index + tab.name}>
                          <NextLink href={tab?.href}>
                            <div
                              className={`flex flex-row items-center rounded-lg cursor-pointer py-3 px-4 mx-3 gap-4 min-w-fit text-white  ${
                                tab.active ? 'bg-white/10' : 'hover:bg-white/10'
                              }`}
                            >
                              <div className="w-12 h-12 flex items-center justify-center rounded-full bg-white/5">
                                {tab.icon}
                              </div>
                              <span className="text-base font-medium">
                                <FormattedMessage
                                  id={tab.name}
                                  defaultMessage={tab.defaultName}
                                />
                              </span>
                            </div>
                          </NextLink>
                        </Menu.Item>
                      )
                    })}
                  </div>
                  <div className="sticky bottom-0 bg-black ">
                    <Menu.Item>
                      <>
                        <div className="h-2.5" />
                        {isUserLoggedIn ? (
                          <div
                            className={`flex flex-row items-center justify-center cursor-pointer py-7 px-4 gap-2 min-w-fit text-[#008000] bg-white/10 relative`}
                            onClick={logoutHandler}
                          >
                            <span className="text-base font-medium">
                              <FormattedMessage
                                id="navbar.authUser.logout"
                                defaultMessage="Logout"
                              />
                            </span>
                            <LogoutIcon className="w-5 h-5 text-green-700" />
                          </div>
                        ) : (
                          <div
                            className={`text-center cursor-pointer py-7 px-4 gap-2 min-w-fit text-[#008000] bg-white/10`}
                            onClick={signinHandler}
                          >
                            <span className="text-base font-medium">
                              <FormattedMessage
                                id="signin.title"
                                defaultMessage="Sign In"
                              />
                            </span>
                          </div>
                        )}
                      </>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <button
              onClick={() => router.push('/login')}
              className="mt-1 inline-block bg-[#008000] py-2 px-2 sm:px-3 text-sm xl:text-base rounded-lg w-[auto] sm:w-[100px]"
            >
              <FormattedMessage id="navbar.login" defaultMessage="Login" />
            </button>
          )}
        </div>
      </div>

      {/* popUp for mobile here */}
      <Transition
        timeout={300}
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className={`absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden`}
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-[#0e1411] divide-y-2 divide-gray-50">
            <div className="pt-5 px-5">
              <div>
                <Popover.Button as={Fragment}>
                  <div
                    className={`text-white rounded-lg cursor-pointer w-10 h-10 flex items-center justify-center`}
                  >
                    <XIcon className="w-5 h-5 " />
                  </div>
                </Popover.Button>
              </div>
              <div className="my-6 sm:mt-8">
                <nav>
                  <div className="grid gap-2">
                    {[...navigation].map((item, index) => {
                      if (item.page === 'casino') {
                        return <CasinoDisclosure key={index} {...item} />
                      } else if (item.page === 'sports') {
                        return <SportsDisclosure key={index} {...item} />
                      }
                      return <HeaderButton key={index} {...item} />
                    })}
                  </div>
                </nav>
              </div>
            </div>
            <div className="py-6 px-5">
              {isUserLoggedIn ? (
                <HeaderButton
                  label="navbar.authUser.logout"
                  defaultLabel="Logout"
                  active
                  centered
                  icon={<LogoutIcon className="w-5 h-5 text-green-700" />}
                  onClick={logoutHandler}
                />
              ) : (
                <div>
                  <NextLink href="/register">
                    <div className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-white/10 hover:bg-white/5">
                      Sign up
                    </div>
                  </NextLink>
                  <p className="mt-6 text-center text-base font-medium text-white/50">
                    Existing user?{' '}
                    <NextLink href="/login">
                      <div className="text-white hover:text-white/80">
                        Sign in
                      </div>
                    </NextLink>
                  </p>
                </div>
              )}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
      {router.pathname === '/sports/[sport]' ||
      router.pathname === '/sports/popular-events' ? (
        <div className="flex sm:hidden px-2 relative items-center justify-between">
          <div
            className={`flex items-center justify-center ${
              router?.query?.sport === 'soccer'
                ? 'w-[45px] h-[45px]'
                : 'w-[30px] h-[30px]'
            } bg-white/10 rounded-full cursor-pointer relative mr-2`}
          >
            <ChevronLeftIcon
              className="w-7 h-7 text-gray-300"
              onClick={handleLeftArrowClick}
            />
          </div>
          <div
            className="overflow-auto whitespace-nowrap w-full"
            ref={containerRef}
          >
            <div className={`flex overflow-auto`}>
              {showIcons &&
                sortedSports?.length > 0 &&
                sortedSports?.map((sport, index) => (
                  <SecondaryIconButton
                    key={index}
                    sport={sport}
                    appendedData={rest}
                    isOpen={isOpen}
                    onClick={() => rest?.setActiveSport(sport?.name)}
                  />
                ))}
              {showIcons && (
                <>
                  <SecondaryIconButton
                    sport={{
                      name: 'motorsports',
                      logo: F1,
                    }}
                    appendedData={rest}
                    isOpen={isOpen}
                    onClick={() => router.push('/sports/futures/motorsports')}
                  />
                  <SecondaryIconButton
                    sport={{
                      name: 'motorsports',
                      logo: Golf,
                    }}
                    appendedData={rest}
                    isOpen={isOpen}
                    onClick={() => router.push('/sports/futures/golf')}
                  />
                </>
              )}
            </div>
          </div>
          <div
            className={`flex items-center justify-center ${
              router?.query?.sport === 'soccer'
                ? 'w-[45px] h-[45px]'
                : 'w-[30px] h-[30px]'
            } bg-white/10 rounded-full cursor-pointer relative ml-2`}
          >
            <ChevronRightIcon
              className="w-7 h-7 text-gray-300"
              onClick={handleRightArrowClick}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      {chatEnabled && (
        <Model onClose={setChat} isOpen={chat}>
          <Chat user={user} paymail={internalWallet?.paymail} />
        </Model>
      )}
    </Popover>
  )
}

export default React.memo(Header)
