import { Insta, TwitterX } from '@/icons/index'
import betSlipsSelector from '@/redux/selectors/betSlips'
import Link from 'next/link'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import '../../../styles/Home.module.css'

const footerLinks = [
  { nameKey: 'footer.casino', defaultName: 'Casino', to: '/casino/plinko' },
  {
    nameKey: 'footer.sports',
    defaultName: 'Sports',
    to: '/sports/popular-events',
  },
  {
    nameKey: 'footer.leaderboard',
    defaultName: 'Leaderboard',
    to: '/leaderboard',
  },
  {
    nameKey: 'footer.blog',
    defaultName: 'Blog',
    to: '/blog',
  },
  { nameKey: 'footer.contact', defaultName: 'Contact', to: '/help' },
  { nameKey: 'footer.faq', defaultName: 'FAQ', to: '/faqs' },
  {
    nameKey: 'footer.termsOfService',
    defaultName: 'Terms of Service',
    to: '/privacy-policy?tab=terms%20of%20services',
  },
  {
    nameKey: 'footer.privacyNotice',
    defaultName: 'Privacy Notice',
    to: '/privacy-policy?tab=privacy',
  },
  {
    nameKey: 'footer.cookiePolicy',
    defaultName: 'Cookie Policy',
    to: '/privacy-policy?tab=cookie%20policy',
  },
  {
    nameKey: 'footer.aml',
    defaultName: 'Anti-Money Laundering',
    to: '/privacy-policy?tab=anti-money%20laundering',
  },
  {
    nameKey: 'footer.selfExclusion',
    defaultName: 'Self-Exclusion',
    to: '/privacy-policy?tab=self-exclusion',
  },
  {
    nameKey: 'footer.responsibleGaming',
    defaultName: 'Responsible Gaming',
    to: '/privacy-policy?tab=responsibleGaming',
  },
]

const footerIcon = [
  {
    src: <TwitterX className="w-6 h-6" />,
    href: 'https://twitter.com/lilbitgame',
  },
  {
    src: <Insta className="w-5 h-5" />,
    href: 'https://www.instagram.com/lilbitgame/',
  },
]

export default function Footer({ width, conditionalFooterWidth, isHome }) {
  const { betslipsOpen } = useSelector(betSlipsSelector)
  const sm = useMediaQuery({ query: '(max-width: 639px)' })

  return (
    <footer
      className={`${width} ml-auto lg:text-left text-center mt-4 sm:pl-8 ${
        isHome ? 'md:pr-4 lg:pr-16' : 'sm:pr-8'
      } overflow-x-hidden`}
    >
      <div
        className={`${conditionalFooterWidth} lg:flex md:justify-between lg:justify-center lg:items-center text-[#EAECF0] text-base pt-8 sm:pt-0`}
      >
        <div className="flex-col">
          <div className="pb-6">
            <ul className="md:flex justify-center flex-wrap gap-x-8">
              {footerLinks.map((link, index) => (
                <li
                  key={index + 'linkList'}
                  className="font-medium hover:text-white/70"
                >
                  <Link href={link.to}>
                    <FormattedMessage
                      id={link.nameKey}
                      defaultMessage={link.defaultName}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className={`${conditionalFooterWidth} pb-7`}>
        <p className="text-sm text-[#98a2b3] mb-4 text-center">
          <FormattedMessage
            id="footer.selfExclusionMessage"
            defaultMessage="In order to register for this website, the user is required to accept the General Terms and
                Conditions. In the event the General Terms and Conditions are updated, existing users
                may choose to discontinue using the products and services before the said update shall
                become effective, which is a minimum of two weeks after it has been announced."
            values={{
              selfExclusionLink: (
                <Link href="/privacy-policy#selfexclusion">
                  <a className="text-[#008000] hover:underline">
                    <FormattedMessage
                      id="footer.selfExclusion"
                      defaultMessage="Self-Exclusion"
                    />
                  </a>
                </Link>
              ),
            }}
          />
        </p>

        <hr className="text-[#EAECF0] px-5" />
      </div>

      <div
        className={`${conditionalFooterWidth} md:flex mb-[70px] sm:mb-0 justify-between text-[#EAECF0] text-base pb-7`}
      >
        <div>
          <p className="text-sm text-[#98a2b3] mb-2 text-center mx-auto w-8/12">
            © {new Date().getFullYear()}{' '}
            <FormattedMessage
              id="footer.copyright"
              defaultMessage="Lilb.it. 2024 lilb.it is operated by LILBIT INC. N.V (Registration No. 160627) having its registered address Abraham de Veerstraat 9, Willemstad, Curaçao. This website is licensed and supervised by Curaçao GCB."
            />
          </p>
        </div>
        <div
          className={`flex justify-center md:mt-0 mt-4 items-center gap-x-2 relative mb-12 sm:mb-0 ${
            sm && betslipsOpen && 'hidden'
          }`}
        >
          {footerIcon.map((icon, index) => (
            <a
              href={icon.href}
              className="shrink-0"
              target="_blank"
              rel="noreferrer noopener"
              key={index}
            >
              {icon.src}
            </a>
          ))}
        </div>
      </div>
    </footer>
  )
}
