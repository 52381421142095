import LimitWarningModal from '@/components/connectWallet/limitWarningModal'
import { APP_URL } from '@/constants/environment'
import { useLanguage } from '@/hooks/languageContext'
import sidebarSelector from '@/redux/selectors/siderbar'
import Head from 'next/head'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import BottomBar from '../bottomBar'
import Footer from '../footer'
import Header from '../header'
import Sportsidebar from '../sportsidebar'

const SharedLayout = ({
  children,
  title,
  showIcons,
  showRightSidebar,
  className = '',
  footer = true,
  sidebar = true,
  headerSpace = true,
}) => {
  const router = useRouter()
  const { isOpen } = useSelector(sidebarSelector)
  const { language, setLanguage } = useLanguage()

  let conditionalWidth = ''
  let conditionalFooterWidth = ''

  if (sidebar && isOpen) {
    conditionalWidth += 'w-[calc(100%-11rem)]'
  } else if (sidebar && !isOpen) {
    conditionalWidth += 'w-full sm:w-[calc(100%-5rem)]'
  } else if (!sidebar) {
    conditionalWidth += 'w-full'
  }

  if (sidebar && isOpen && showRightSidebar) {
    conditionalFooterWidth += 'lg:w-[calc(100%-18rem)] w-full'
  } else if (sidebar && !isOpen && !showRightSidebar) {
    conditionalFooterWidth += 'lg:w-full w-full'
  } else if (sidebar && !isOpen && showRightSidebar) {
    conditionalFooterWidth += 'lg:w-[calc(100%-18rem)] w-full'
  } else if (sidebar && isOpen && !showRightSidebar) {
    conditionalFooterWidth += 'lg:w-full w-full'
  } else if (!sidebar && !isOpen && showRightSidebar) {
    conditionalFooterWidth += 'lg:w-[calc(100%-18rem)] w-full'
  } else if (!sidebar) {
    conditionalFooterWidth += 'w-full'
  }
  return (
    <div className="min-h-screen h-full hero-backgroud overflow-x-hidden">
      <Head>
        <title>{`${title ? title + ' ' + '|' : ''} Lilbit`}</title>
        <link rel="icon" href="/svgs/favicon.svg" />
        <meta
          name="description"
          content="Find the best casino, sports, bets, plinko, and games on Lilbit. Sign up now to get started."
        />
        <meta
          name="keywords"
          content="casino, sports, bets, plinko, games, betting, gambling, online casino, sportsbook, esports, virtual sports, live casino, table games, slots, roulette, blackjack, poker, baccarat"
        />
        <meta
          property="og:title"
          content="Best Casino, Sports, Bets, Plinko, and Games | Lilbit"
        />
        <meta
          property="og:description"
          content="Find the best casino, sports, bets, plinko, and games on Lilbit. Sign up now to get started."
        />
        <meta property="og:image" content="/img/OpenGraph.png" />
        <meta property="og:url" content={APP_URL} />

        <link rel="canonical" href={APP_URL} />
        <h1 className="hidden">
          lilbit,casino, sports, bets, plinko, games, betting, gambling, online
          casino, sportsbook, esports, virtual sports, live casino, table games,
          slots, roulette, blackjack, poker, baccarat
        </h1>
      </Head>
      <div className="w-full h-full">
        {sidebar && (
          <Sportsidebar
            isOpen={isOpen}
            showIcons={showIcons}
            footballIcon={true}
          />
        )}
        <div className={`min-h-[100dvh] h-full w-full`}>
          <Header
            isOpen={isOpen}
            isSidebarOpen={sidebar}
            width={conditionalWidth}
            showIcons={showIcons}
            language={language}
            setLanguage={setLanguage}
          />
          <div
            className={`${
              router.pathname.includes('sports') &&
              !router.pathname.includes('pool')
                ? 'h-[152px] sm:h-[76px]'
                : 'h-[86px]'
            }  w-full`}
          />
          <main className={`${className} relative ${conditionalWidth} ml-auto`}>
            {children}
          </main>

          {footer && (
            <Footer
              width={conditionalWidth}
              conditionalFooterWidth={conditionalFooterWidth}
              isSidebarOpen={sidebar}
              isHome={router.pathname.includes('home') && true}
            />
          )}
          <BottomBar />
          <LimitWarningModal />
        </div>
      </div>
    </div>
  )
}

SharedLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default memo(SharedLayout)
